<template>
  <div class="PricePostBlock">
    <div class="block-input price-input _usd">
      <label tcenter v-html="`${$locale['price_dollars']} (USD)`"></label>
      <div class="inputFake">
        <input tcenter type="number" name="price_usd" v-model="price_usd" placeholder="1" @input="priceInput" />
        <span class="usdPriceLabel">$ {{ Go.number_format(price_usd, 2) }}</span>
      </div>
    </div>
    <div class="block-input price-input credits">
      <label class="postPriceLabel" tcenter>
        <span>{{ $locale["price_credits"] }}</span>
        <span dcolor><iconic name="mf_lock" /></span>
      </label>
      <div class="inputFake">
        <input tcenter type="number" name="price" :value="price" placeholder="1" readonly />
        <span class="usdPriceLabel">
          <span class="usdPriceLabelIcon"><iconic name="coins" /></span>
          <span>{{ Go.number_format(price) }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      price: 0,
      price_usd: 0,
    };
  },
  methods: {
    priceInput: function(e) {
      this.price_usd = e.target.value;
      this.price = Go.number_format(this.price_usd / this.creadis_val);
    },
  },
  computed: {
    creadis_val: function() {
      return Number(this.$locale["credits_value"]) - Number(this.$locale["ptfm_fee"]);
    },
  },
};
</script>
